import { useState, useMemo } from 'react';
import useProductQueryParams from 'hooks/use-product-query-params';
import { GenericCategory } from 'src/generics/data';

type CategoryOption = {
  key: string;
  label: string;
  value: string;
};

type CategorySubcategories = {
  category: CategoryOption;
  subcategories: CategoryOption[] | [];
};

type UseCategorySubcategoryPillsParams = {
  categoriesSubcategories: CategorySubcategories[];
};

type UseCategorySubcategoryPillsReturn = {
  selectedCategoryOption: CategoryOption | null;
  selectedSubcategories: string[] | null;
  showCategorySelect: boolean;
  subcategoriesForCategory: CategoryOption[] | null;
  availableCategories: CategoryOption[] | [];
  setShowCategorySelect: (state: boolean) => void;
  handleSelectCategory: (categoryValue: GenericCategory | string | null) => void;
  handleSelectSubcategory: (categoryValue: GenericCategory | string | null, isSelected: boolean) => void;
};

type AvailableSubcategoriesByCategory = Record<string, CategoryOption[]>;

function getAvailableCategorySubcategoryMap(
  categoriesSubcategories: CategorySubcategories[]
): AvailableSubcategoriesByCategory {
  const categorySubcategoryMap = {};
  if (categoriesSubcategories.length > 0) {
    categoriesSubcategories.forEach((catSub) => {
      categorySubcategoryMap[`${catSub.category.key}`] = catSub.subcategories;
    });
  }

  return categorySubcategoryMap;
}

export function useCategorySubcategoryPills({
  categoriesSubcategories,
}: UseCategorySubcategoryPillsParams): UseCategorySubcategoryPillsReturn {
  const { defaults, queryParams, setQueryParams } = useProductQueryParams();

  const selectedCategory = queryParams.category;
  const selectedSubcategories = queryParams.subcategories;

  const [showCategorySelect, setShowCategorySelect] = useState<boolean>(false);

  const availableCategorySubcategoryMap = useMemo(() => getAvailableCategorySubcategoryMap(categoriesSubcategories), [
    categoriesSubcategories,
  ]);

  const availableCategories = categoriesSubcategories.map((catSub) => catSub.category);
  const [selectedCategoryOption] = availableCategories.filter(
    (categoryOption) => categoryOption.key === selectedCategory
  );

  const handleSelectCategory = (categoryValue: string | null): void => {
    setQueryParams({
      category: categoryValue ?? defaults.category,
      subcategories: defaults.subcategories,
      page: defaults.page,
    });
    setShowCategorySelect(false);
  };

  const handleSelectSubcategory = (subcategoryValue: string | null, isSelected: boolean): void => {
    setQueryParams({
      subcategories: isSelected ? [] : [subcategoryValue],
      page: defaults.page,
    });
  };

  return {
    selectedCategoryOption,
    selectedSubcategories,
    showCategorySelect,
    setShowCategorySelect,
    availableCategories,
    subcategoriesForCategory: availableCategorySubcategoryMap[selectedCategory],
    handleSelectCategory,
    handleSelectSubcategory,
  };
}
