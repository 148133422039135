import { SponsoredProductsInventoryId } from 'types/graphql';
import { Category } from 'src/generics/data/generic-category/data.types';
import { usePrependWithSponsoredProducts } from 'src/utils/ads/hooks/sponsored-products';
import { useDispensaryCategory } from 'src/hooks/use-dispensary-category';
import useProductQueryParams from 'src/hooks/use-product-query-params';

import { useGetSortedProductRecommendationsForCategoryPage } from 'src/personalization/utils/hooks';
import { useShouldSkipAds } from './use-skip-ads-rules';
import useDispensaryProducts from './use-dispensary-products';
import {
  useCategoryProductsForCarouselsQueries,
  CategoryProductsCarouselsQueries,
} from './use-category-products-for-carousels-queries';

type ProductsForCategoryPage = ReturnType<typeof useDispensaryProducts> & {
  productsCarouselQueries: CategoryProductsCarouselsQueries;
};

export function useProductsForCategoryPage({ perPage }: { perPage: number }): ProductsForCategoryPage {
  const { queryParams: qp } = useProductQueryParams();
  const dispensaryCategory = useDispensaryCategory();

  const skipAds = useShouldSkipAds();
  const hasBrandsFilter = !!qp.brands.length;
  const limit = 5;
  const category = dispensaryCategory?.value as Category;
  const searchQuery = qp.search;
  const inventoryId = searchQuery
    ? SponsoredProductsInventoryId.searchProductAuc
    : SponsoredProductsInventoryId.categoryProductAuc;

  const productsCarouselQueries = useCategoryProductsForCarouselsQueries({
    category,
    skipAds,
  });

  const {
    productIds: recommendedProductIds,
    loading: sortedRecommendationsLoading,
  } = useGetSortedProductRecommendationsForCategoryPage({ category });

  const { products: organicProducts, loading, error, ...rest } = useDispensaryProducts({
    filters: { productIds: recommendedProductIds },
    perPage,
    sortBy: !hasBrandsFilter ? qp.sortby : undefined,
  });

  const sponsoredProductsResult = usePrependWithSponsoredProducts({
    skip: skipAds,
    limit,
    inventoryId,
    category,
    searchQuery,
    products: organicProducts,
    loading,
    error,
  });

  return {
    ...sponsoredProductsResult,
    ...rest,
    loading: sponsoredProductsResult.loading || productsCarouselQueries.loading || sortedRecommendationsLoading,
    productsCarouselQueries,
  };
}
