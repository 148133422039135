import { useGetPersonalizedProductsQuery, useGetSponsoredBrandQuery } from 'types/graphql';
import { Category } from 'src/generics/data/generic-category/data.types';

import { useAdInventoryId, ADS_FETCH_POLICY } from 'src/utils/ads/helpers';
import { useHasValidSponsoredBrandPlacement } from 'src/utils/ads/hooks/sponsored-brand';

import useDispensary from 'src/dispensary/hooks/use-dispensary';
import useCart from 'src/hooks/use-cart';
import { useShouldSkipPersonalization } from './use-skip-ads-rules';

type CategoryProductsCarouselQueryArgs = {
  category?: Category | null;
  skipAds: boolean;
};

export type CategoryProductsCarouselsQueries = {
  loading: boolean;
  sponsoredBrandQuery: ReturnType<typeof useGetSponsoredBrandQuery>;
  personalizedProductsQuery: ReturnType<typeof useGetPersonalizedProductsQuery>;
};

export function useCategoryProductsForCarouselsQueries({
  category,
  skipAds,
}: CategoryProductsCarouselQueryArgs): CategoryProductsCarouselsQueries {
  const { dispensary } = useDispensary();
  const { menuType } = useCart();

  const dispensaryId = dispensary?.id;
  const isReady = !!dispensaryId;
  const inventoryId = useAdInventoryId(dispensaryId, category);
  const hasValidSponsoredBrandPlacement = useHasValidSponsoredBrandPlacement('category-page');
  const skipPersonalization = useShouldSkipPersonalization();

  const alwaysSkip = !isReady || !category;
  const skipAdQuery = alwaysSkip || !hasValidSponsoredBrandPlacement || skipAds;
  const skipPersonalizationQuery = alwaysSkip || skipPersonalization;

  const sponsoredBrandQuery = useGetSponsoredBrandQuery({
    fetchPolicy: ADS_FETCH_POLICY,
    skip: skipAdQuery,
    variables: {
      input: {
        dispensaryId,
        category,
        inventoryId,
        minimumProducts: 3,
      },
    },
  });

  const personalizedProductsQuery = useGetPersonalizedProductsQuery({
    fetchPolicy: 'network-only',
    skip: skipPersonalizationQuery,
    variables: {
      input: {
        dispensaryId,
        limit: 25,
        filter: {
          menuType,
          category,
        },
      },
    },
  });

  return {
    loading: !isReady || sponsoredBrandQuery.loading || personalizedProductsQuery.loading,
    sponsoredBrandQuery,
    personalizedProductsQuery,
  };
}
