import React from 'react';
import styled from 'styled-components';
import { Dropdown } from 'components/core';
import { Clickable } from 'shared/components';

type CategoryOption = {
  key: string;
  label: string;
  value: string;
};

type CategoryDropdownModalProps = {
  categories: CategoryOption[] | null;
  selectedCategoryValue?: string;
  handleCategorySelect: (categoryValue: string | null) => void;
};

const ALL_CATEGORY_LABEL = 'All Categories';

export function CategoryDropdownModal({
  categories,
  selectedCategoryValue,
  handleCategorySelect,
}: CategoryDropdownModalProps): JSX.Element {
  return (
    <StyledDropdown>
      <LinkList>
        <ListItemContainer>
          <LinkContainer>
            <Anchor onClick={() => handleCategorySelect(null)} selected={!selectedCategoryValue}>
              {ALL_CATEGORY_LABEL}
            </Anchor>
          </LinkContainer>
        </ListItemContainer>
        {categories?.map(
          (category): JSX.Element => (
            <ListItemContainer key={category.key}>
              <LinkContainer>
                <Anchor
                  onClick={() => handleCategorySelect(category.key)}
                  selected={category.key === selectedCategoryValue}
                >
                  {category.label}
                </Anchor>
              </LinkContainer>
            </ListItemContainer>
          )
        )}
      </LinkList>
    </StyledDropdown>
  );
}
const Anchor = styled(Clickable)`
  ${({ selected }) => selected && `font-weight: bold`};
  padding: 0 30px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.grey[25]};
  font-size: 14px;
  font-weight: ${(props) => (props.selected ? `bold` : `normal`)};
  line-height: 2;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const LinkList = styled.ol`
  list-style: none;
  margin: 0;
  padding: 19px 0 24px 0;

  :first-child {
    padding-top: 0;
  }

  :last-child {
    padding-bottom: 0;
  }
`;

const ListItemContainer = styled.div`
  width: 100%;
  padding: 0;
  height: 42px;
  display: flex;
  align-items: center;
  &:hover {
    background: ${({ theme }) => theme.colors.primaryGrey};
  }
`;

const LinkContainer = styled.li`
  width: 100%;
  height: 100%;
`;

const StyledDropdown = styled(Dropdown)`
  color: ${({ theme }) => theme.colors.primaryBlack};
  position: relative;
  border-radius: 8px;
`;
