import styled, { css } from 'styled-components';
import React from 'react';

import { Button } from 'components/core';
import SvgChevronIcon from 'src/assets/chevron-icon';

type FilterPillProps = {
  selected?: boolean;
  label: string;
  onClick: () => void;
  enableDropdown?: boolean;
  reference?: React.RefObject<HTMLDivElement> | null;
};

export function FilterPill({
  selected = false,
  label,
  onClick,
  enableDropdown = false,
  reference = null,
}: FilterPillProps): JSX.Element {
  return (
    <Container ref={reference}>
      <Pill selected={selected} onClick={onClick}>
        {label}
        {enableDropdown && <SvgChevronIcon active={selected.toString()} height={12} width={11} />}
      </Pill>
    </Container>
  );
}

const Container = styled.div`
  margin-right: 8px;
`;

const selectedStyles = css`
  background-color: ${({ theme }) => theme.colors.grey[80]};
  color: ${({ theme }) => theme.colors.primaryBlack};
`;

const Pill = styled(Button)`
  text-transform: capitalize;
  white-space: nowrap;
  font-size: 12px;
  height: 35px;
  padding: 11px 15px;
  color: ${({ theme }) => theme.colors.grey[30]};
  background-color: ${({ theme }) => theme.colors.blueGrey[95]};
  ${({ selected }) => selected && selectedStyles}
  svg {
    margin-right: unset;
    margin-left: 5px;
    fill: currentColor;
  }
`;
