import React, { useRef } from 'react';
import styled from 'styled-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Popover from '@material-ui/core/Popover';

import { useCategorySubcategoryPills } from './use-category-subcategory-pills';
import { FilterPill } from './filter-pill';
import { CategoryDropdownModal } from './category-dropdown-modal';

type CategoryOption = {
  key: string;
  label: string;
  value: string;
};

type CategorySubcategories = {
  category: CategoryOption;
  subcategories: CategoryOption[] | [];
};

type CategorySubcategoryPillsProps = {
  categoriesSubcategories: CategorySubcategories[] | [];
};

export function CategorySubcategoryPills({ categoriesSubcategories }: CategorySubcategoryPillsProps): JSX.Element {
  const {
    selectedCategoryOption,
    selectedSubcategories,
    showCategorySelect,
    setShowCategorySelect,
    availableCategories,
    subcategoriesForCategory,
    handleSelectCategory,
    handleSelectSubcategory,
  } = useCategorySubcategoryPills({ categoriesSubcategories });
  const flags = useFlags();
  const categorySelectRef = useRef<HTMLDivElement>(null);

  const updatedFilterIconsEnabled = flags['growth.ecomm.filter-icon-update.rollout'] ?? false;

  return (
    <Container className='subcategories-pills' marginTop={updatedFilterIconsEnabled ? '25px' : '40px'}>
      <PillsContainer>
        <FilterPill
          selected
          label={selectedCategoryOption?.label ?? `All Categories`}
          onClick={() => setShowCategorySelect(true)}
          enableDropdown
          reference={categorySelectRef}
        />
        {subcategoriesForCategory?.map((subcat) => {
          const isSelected = !!selectedSubcategories?.includes(subcat.key);
          return (
            <FilterPill
              key={subcat.key}
              selected={isSelected}
              label={subcat.label}
              enableDropdown={false}
              onClick={() => handleSelectSubcategory(subcat.key, isSelected)}
            />
          );
        })}
      </PillsContainer>
      <CategoryDropdown
        open={showCategorySelect}
        anchorEl={categorySelectRef.current}
        onClose={() => setShowCategorySelect(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <CategoryDropdownModal
          categories={availableCategories}
          selectedCategoryValue={selectedCategoryOption?.key}
          handleCategorySelect={handleSelectCategory}
        />
      </CategoryDropdown>
    </Container>
  );
}

const PillsContainer = styled.div`
  display: flex;
  overflow: scroll;
  padding-left: 25px;

  > :last-child {
    padding-right: 25px;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Container = styled.div`
  display: flex;
  margin-top: ${(props) => props.marginTop};
  width: 100%;
`;

const CategoryDropdown = styled(Popover)`
  margin-top: 5px;
`;
